import React, {Component} from 'react';
import './Help.css';
import './TopMenu.css';
import './BotomMenu.css';
import firebaseApp from './firebase'
import {getAnalytics, logEvent, setCurrentScreen} from "firebase/analytics";

const analytics = getAnalytics(firebaseApp);

let _this;

class Help extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;
    }

    componentDidMount() {
        setCurrentScreen(analytics, window.location.pathname);
        logEvent(analytics, 'screen_view')
    }

    render() {
        return <div>
            <div className="container">
                <br/>
                <div className="card" id="shellPrimaryNoPadding">
                    <div className="top_menu containerNoMargin">
                        <ul>
                            <li>
                                <a href="#home" role="button" onClick={() => _this.props.navigate('Home')}>Home</a>
                            </li>
                            <li className="current" >
                                <a href="#help" role="button" onClick={() => _this.props.navigate('Help')}>Help</a>
                            </li>
                            <li>
                                <a href="#download" role="button" onClick={() => _this.props.navigate('Download')}>Download</a>
                            </li>
                            <li>
                                <a href="#policy" role="button" onClick={() => _this.props.navigate('Policy')}>Privacy
                                    Policy</a>
                            </li>
                            <li>
                                <a href="mailto:feedback@answersolutions.net?subject=StudyCards">Contact us</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <br/>
                <br/>
                <h2>For More Information about ChatGPT Wizard check out this tutorial:</h2>
                <a href="https://medium.com/geekculture/create-custom-flashcards-for-studycards-app-with-the-new-chatgpt-wizard-a-step-by-step-guide-e2326279427c" target="_blank" rel="noopener noreferrer">ChatGPT Wizard Tutorial</a>
                <br/>
                <br/>
                <br/>
                <div id="shell1">
                    <div id="content">
                        <h2>Home screen / List of decks</h2>
                        <p>Tap the <b>Hide</b> buttons to hide the default demo decks and user guides.<br/><br/><img
                            src='hide.png' width="100"/></p>
                        <p>Use the <b>Show</b> button to return those decks to the screen.<br/><br/><img src='show.png'
                                                                                                         width="100"/><br/>
                        </p>
                        <p>Tap the <b>New</b> button to create a new deck of cards.<br/><br/><img src='new.png'
                                                                                                  width="100"/><br/></p>
                        <p>Also, press the <b>New</b> button to import a file with a custom deck.</p>
                        <p>Tap the <b>Edit</b> button to navigate to the list representation of the deck.<br/><br/><img
                            src='edit.png' width="100"/><br/></p>
                        <p>Also, in <b>Edit mode</b>, you can remove a custom deck from the app.<br/><br/><img
                            src='delete.png' width="100"/><br/></p>
                        <p><b>Hint.</b><br/>You can remove the only custom decks from the <b>Homescreen</b>.<br/></p>
                        <p><b>Hint.</b><br/>A name of deck can not be empty. Do not use semicolons (;), colons (:),
                            brackets (()), quotes, comas (,) or backspace symbols. Also, make sure the name is no longer
                            than 20 characters!</p>
                    </div>
                </div>

                <br/>
                <div id="shell2">
                    <div id="content">
                        <h2>
                            Deck Screen
                        </h2>
                        <p>Swipe the cards one by one to learn the material.<br/></p>
                        <p>Try to recall without peeping!</p>
                        <p>Tap <b>Peep</b> to see the <b>answer/hint/translation</b> on the backside of the
                            card.<br/></p>
                        <p>Next time the card you peeped will appear first in the deck. Tap <b>Back</b> to flip the card
                            back.</p>
                        <p>To better remember the material, tap the <b>Say</b> button to hear the auditory version of
                            the info.<br/></p>
                        <p>Tap the <b>Play</b> button to use the app in the <i>hands/eyes-free</i> mode.</p>
                        <p>The app will pronounce both sides, card by card until you hit the <b>Stop</b> button or exit
                            the screen.<br/></p>
                        <p>The app speaks in the background, so you can listen to it while you walk or drive with
                            Waze/Maps!</p>
                        <p>Tapping on the <b>Speaker</b> button, you can control the audio destination.<br/></p>
                        <p>The buttons <b>Speaker/Headphones</b> switching voice between a <b>Speaker</b> and a
                            connected <b>Headphones</b>.</p>
                        <p>To enable <b>Send to Watch</b> button, launch the <i>StudyCards</i> app on
                            your <i>smartwatch</i>.<br/><br/><img src='scw0.png' width="100"/><br/></p>
                        <p>Tap <b>Send to Watch</b> to transfer the current deck to the <b>connected</b> smartwatch.</p>
                        <p>Use the <b>Segmented Control</b> on the top of the screen to shuffle the deck and to exchange
                            the sides of all cards.<br/><br/><img src='segm.png' width="100"/><br/></p>
                        <p>Now you will see the <i>answer/hint/translation</i> first. Can you recall the question
                            without using the <b>Peep</b> button again?</p>
                        <p>Tap the <b>plus</b> button on the bottom-right to add a new card.<br/><br/><img
                            src='add.png' width="100"/><br/></p>
                        <p>Look at <b>New/Edit Deck Guide</b> to learn how to add new deack and card. <br/>Keep in mind
                            that <b>share</b> and <b>plus</b> buttons are available to the custom decks only!</p>
                        <p>Tap the <b>share</b> button on the top-right, to export current deck into a file, and to send
                            it by email to your friends.<br/><br/><img src='share.png' width="100"/><br/></p>
                        <p><i>Hint.</i> Send all your custom decks to yourself to back your work up!</p>

                    </div>
                </div>

                <br/>
                <div id="shell3">
                    <div id="content">
                        <h2>
                            Edit Deck
                        </h2>
                        <p>Input the front side text, and tap the play button above to hear how the talking card will
                            sound.<br/><br/><img src='play.png' width="30"/><br/></p>
                        <p>By default, the app uses <i>English</i> voice, yet you can change the language, for the front
                            side tap the <i>Yellow</i> button on the table's top.<br/><br/><img
                                src='lang.png' width="200"/><br/>If it sounds right, press the Keyboard->Next(->) button.</p>
                        <p>Input the back side text, and tap the play button above to hear how the talking card will
                            sound.<br/><br/><img src='play.png' width="30"/><br/></p><p>By default, the app
                        uses <i>English</i> voice, yet you can change the language for the back side tap
                        the <i>Blue</i> button on the table's top.<br/><br/><img src='lang.png' width="200"/><br/>If it sounds
                        right, press the Keyboard->Done button.</p>
                        <p>To change/edit a card select its row in the table, change the front side's text, tap
                            Keyboard->Next, change the text of the backside, and tap Keyboard->Done.<br/><br/><img
                                src='edit_row.png' width="200"/><br/></p><p>Don't forgot to <i>Save</i> your work! To save your work
                        Tap the <b>Save</b> button on the top. Also, the deck will be saved when you exit from the
                        screen.</p>
                        <p>Tap <b>Add</b> button to add a new card at the top of the table/deck.<br/></p><p>Add
                        button appears when the <i>SoftKeybaord</i> is hidden.</p>
                        <p>You can <b>copy/paste</b> a list of strings separated by semicolons or backspace.<br/>
                        </p><p>Go to an external app, website, google translator, etc., prepare two columns of strings,
                        copy/paste first column into the front side input, then second column into the back side input,
                        and tap Keyboard->Done.</p>
                        <p>To change a name of the deck, tap the <b>Edit</b> button.<br/></p><p>In the <i>Edit
                        mode</i>, you can also remove the rows (cards) from the list.</p>
                        <p>You can control voice, to add a pausa use a dot (.) or comma (,).<br/></p><p>Use
                        brackets () for the text that the app should not pronounce, e.g., English transcription for
                        changes hieroglyph!<br/><br/><img src='ch.png' width="200"/><br/></p>
                        <p>A name of deck can not be empty! Do not use semicolons (;), colons (:), brackets (()),
                            quotes, comas (,) or backspace symbols.<br/></p><p>Also, make sure the name is no
                        longer than 20 characters!</p>

                    </div>
                </div>

                <br/>
                <div id="shell4">
                    <div id="content">
                        <h2>
                            Watch
                        </h2>
                        <p>Make sure you have the <b>StudyCards</b> app installed on your <b>Watch!</b><br/><br/><img
                            src='scw0.png' width="100"/><br/></p>
                        <p>To send a deck of cards from the Phone's app, select a deck and tap on <b>Send to
                            Watch</b> button on the bottom.<br/><br/><img src='send2w.png' width="200"/><br/>Lunch the
                            app on a
                            watch to enable this button!</p>
                        <p>Tap on the watch screen to see the next card.<br/><br/><img src='scw1.png' width="100"/><br/>
                        </p>
                        <p>Try to recall without peeping!</p>
                        <p>Tap <b>Peep</b> to see the answer/hint/translation on the backside of the card.<br/><br/><img
                            src='scw1.png' width="100"/><br/></p>
                        <p>Tap <b>Back</b> to return to the front side.<br/><br/><img src='scw2.png'
                                                                                      width="100"/><br/></p>
                        <p>Tap the <b>Play</b> button to use the app in the hands/eyes-free mode.<br/><br/><img
                            src='scw1.png' width="100"/><br/></p>
                        <p>The app will pronounce both sides, card by card until you hit the <b>Stop</b> button or close
                            the app.<br/><br/><img src='scw3.png' width="100"/><br/></p>
                        <p>Make sure your <b>Bluetooth headphone</b> is connected.<br/></p>
                        <p><b>StudyCards</b><br/>can read the cards when the app in the background, so you can study
                            while running, and even you can study alongside with some music app playing ambient music -
                            better without any voice.</p>
                    </div>
                </div>
                <br/>
                <div className="card" id="shellPrimaryNoPadding">
                    <div className="fast_nav containerNoMargin">
                        <ul>
                            <li className="first">
                                <a href="https://answersolutions.net" target="_blank" rel="noopener noreferrer">2022 ©
                                    AnswerSolutions LLC</a>
                            </li>
                            <li>
                                <a href="#home" role="button" onClick={() => _this.props.navigate('Home')}>Home</a>
                            </li>
                            <li className="current">
                                <a href="#help" role="button" onClick={() => _this.props.navigate('Help')}>Help</a>
                            </li>
                            <li>
                                <a href="#download" role="button"
                                   onClick={() => _this.props.navigate('Download')}>Download</a>
                            </li>
                            <li>
                                <a href="#policy" role="button" onClick={() => _this.props.navigate('Policy')}>Privacy
                                    Policy</a>
                            </li>
                            <li>
                                <a href="mailto:feedback@answersolutions.net?subject=StudyCards">Contact us</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <br/>
            </div>
        </div>
    }
}

export default Help;