// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB2_e8bMDtibSl_Ss5Ki581B1L4qdzkxKA",
    authDomain: "studycards-app.firebaseapp.com",
    projectId: "studycards-app",
    storageBucket: "studycards-app.appspot.com",
    messagingSenderId: "152739919151",
    appId: "1:152739919151:web:744cee668e99ec64749dda",
    measurementId: "G-HDRGG5Q4XR"
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;

