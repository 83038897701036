import React, {Component} from 'react';
import './Home.css';
import firebaseApp from './firebase'
import {getAnalytics, logEvent, setCurrentScreen} from "firebase/analytics";

const analytics = getAnalytics(firebaseApp);

let _this;

class Blogs extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;
    }

    componentDidMount() {
        setCurrentScreen(analytics, window.location.pathname);
        logEvent(analytics, 'screen_view')
    }

    render() {
        return <div>
            <div className="container">


                <div className="fast_nav container">
                    <ul>
                        <li className="first">
                            2021 © AnswerSolutions LLC
                        </li>
                        <li>
                            <a href="#home" role="button" onClick={() => _this.props.navigate('Home')}>Home</a>
                        </li>
                        <li>
                            <a href="#help" role="button" onClick={() => _this.props.navigate('Help')}>Help</a>
                        </li>
                        <li>
                            <a href="#download" role="button" onClick={() => _this.props.navigate('Download')}>Download</a>
                        </li>
                        <li>
                            <a href="mailto:feedback@answersolutions.net?subject=StudyCards">Contact us</a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    }
}

export default Blogs;