import React, {Component} from 'react';

import Home from "./Home";
import Help from "./Help";
import Policy from "./Policy"
import Blogs from "./Blogs"
import Download from "./Download"
import AdIos from "./AdIos"
import AdAndroid from "./AdAndroid";


let _this;


class App extends Component {
    constructor(props, context) {
        super(props, context);

        let init_screen = 'Home';
        if (window.location.hash === '#home'){
            init_screen = 'Home';
        }else  if (window.location.hash === '#help'){
            init_screen = 'Help';
        }else  if (window.location.hash === '#policy'){
            init_screen = 'Policy';
        }else  if (window.location.hash === '#download'){
            init_screen = 'Download';
        }else  if (window.location.hash === '#adios'){
            init_screen = 'AdIos';
        }else  if (window.location.hash === '#adandroid'){
            init_screen = 'AdAndroid';
        }else  if (window.location.hash === '#blogs'){
            init_screen = 'Blogs';
        }

        this.state = {
            target: init_screen
        };
        _this = this;

    }

    componentDidMount() {
        console.log('componentDidMount=>');
    }

    navigate(new_target) {
        console.log('navigate=>' + new_target);
        _this.setState({
            target: new_target,
        });
    };

    render() {

        let screen = null;
        if (this.state.target === 'Home') {
            screen = <Home navigate={this.navigate}/>
        } else if (this.state.target === 'Help') {
            screen = <Help navigate={this.navigate}/>
        } else if (this.state.target === 'Policy') {
            screen = <Policy navigate={this.navigate}/>
        }else if (this.state.target === 'Download') {
            screen = <Download navigate={this.navigate}/>
        }else if (this.state.target === 'AdIos') {
            screen = <AdIos navigate={this.navigate}/>
        }else if (this.state.target === 'AdAndroid') {
            screen = <AdAndroid navigate={this.navigate}/>
        }else if (this.state.target === 'Blogs') {
            screen = <Blogs navigate={this.navigate}/>
        }

        return (
            <div className="App">
                {screen}
            </div>
        );
    }
}

export default App;
