import React, {Component} from 'react';
import './Home.css';
import './TopMenu.css';
import './BotomMenu.css';
import firebaseApp from './firebase'
import {getAnalytics, logEvent, setCurrentScreen} from "firebase/analytics";

const analytics = getAnalytics(firebaseApp);

let _this;

class Download extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;
    }

    componentDidMount() {
        setCurrentScreen(analytics, window.location.pathname);
        logEvent(analytics, 'screen_view')
    }


    render() {
        return <div className="container">
            <br/>
            <div className="card" id="shellPrimaryNoPadding">
                <div className="top_menu containerNoMargin">
                    <ul>
                        <li>
                            <a href="#home" role="button" onClick={() => _this.props.navigate('Home')}>Home</a>
                        </li>
                        <li>
                            <a href="#help" role="button" onClick={() => _this.props.navigate('Help')}>Help</a>
                        </li>
                        <li className="current" >
                            <a href="#download" role="button" onClick={() => _this.props.navigate('Download')}>Download</a>
                        </li>
                        <li>
                            <a href="#policy" role="button" onClick={() => _this.props.navigate('Policy')}>Privacy
                                Policy</a>
                        </li>
                        <li>
                            <a href="mailto:feedback@answersolutions.net?subject=StudyCards">Contact us</a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
            <h2>Download Decks to your Phone</h2>
            <h4>Coming more soon</h4>
            <br/>
            <a href="/decs/Roblox_Terms.studyCards" download style={{textDecoration: 'none', color: '#040404'}}>
                <div className="downloadTableTop" id="downloadTableTop">
                    <div id="content">
                        <h2>Roblox Terms and Slang for Parents (20 flashcards)</h2>
                        <p>English to English</p>
                    </div>
                </div>
            </a>
            <a href="/decs/Minecraft_Terms.studyCards" download style={{textDecoration: 'none', color: '#040404'}}>
                <div className="downloadTableMiddle" id="downloadTableMiddle">
                    <div id="content">
                        <h2>Minecraft Terms and Slang for Parents (15 flashcards)</h2>
                        <p>English to English</p>
                    </div>
                </div>
            </a>

            <a href="/decs/pi_facts.studyCards" download style={{textDecoration: 'none', color: '#040404'}}>
                <div className="downloadTableMiddle" id="downloadTableMiddle">
                    <div id="content">
                        <h2>50 facts about the Pi number (51 flashcards)</h2>
                        <p>English to English</p>
                    </div>
                </div>
            </a>
            <a href="/decs/st_patric_facts.studyCards" download style={{textDecoration: 'none', color: '#040404'}}>
                <div className="downloadTableMiddle" id="downloadTableMiddle">
                    <div id="content">
                        <h2>50 facts about the St. Patric's Day (50 flashcards)</h2>
                        <p>English to English</p>
                    </div>
                </div>
            </a>

            <a href="/decs/140_English.studyCards" download style={{textDecoration: 'none', color: '#040404'}}>
                <div className="downloadTableMiddle" id="downloadTableMiddle">
                    <div id="content">
                        <h2>140 English (140 flashcards)</h2>
                        <p>English to Russian</p>
                    </div>
                </div>
            </a>
            <a href="/decs/6_Haikus.studyCards" download style={{textDecoration: 'none', color: '#040404'}}>
                <div className="downloadTableMiddle" id="downloadTableMiddle">
                    <div id="content">
                        <h2>6 Haiku (6 flashcards)</h2>
                        <p>English to English</p>
                    </div>
                </div>
            </a>
            <a href="/decs/Geometry.studyCards" download style={{textDecoration: 'none', color: '#040404'}}>
                <div className="downloadTableMiddle" id="downloadTableMiddle">
                    <div id="content">
                        <h2>Geometry (14 flashcards)</h2>
                        <p>English to English</p>
                    </div>
                </div>
            </a>
            <a href="/decs/50_Android_InterviewQ&A.studyCards" download
               style={{textDecoration: 'none', color: '#040404'}}>
                <div className="downloadTableBottom" id="downloadTableBottom">
                    <div id="content">
                        <h2>50 Android Interview Questions</h2>
                        <p>English to English</p>
                    </div>
                </div>
            </a>
            <p>
                <div className="fast_nav container">
                    <h2>Download Mobile app</h2>
                    <ul>
                        <li className="first">
                            <a href="https://apps.apple.com/us/app/study-cards-help-to-memorize/id1436913731"
                               target="_blank"
                               rel="noopener">
                                <img src="/app_store.png" scale="0" alt="itunes"/>
                            </a>
                        </li>
                        <li>
                            <a href="https://play.google.com/store/apps/details?id=com.nes.studycards"
                               target="_blank"
                               rel="noopener">
                                <img src="/google_play.png" scale="0" alt="google play"/>
                            </a>
                        </li>
                    </ul>
                </div>
            </p>
            <br/>
            <div className="card" id="shellPrimaryNoPadding">
                <div className="fast_nav containerNoMargin">
                    <ul>
                        <li className="first">
                            <a href="https://answersolutions.net" target="_blank" rel="noopener noreferrer">2022 ©
                                AnswerSolutions LLC</a>
                        </li>
                        <li>
                            <a href="#home" role="button" onClick={() => _this.props.navigate('Home')}>Home</a>
                        </li>
                        <li>
                            <a href="#help" role="button" onClick={() => _this.props.navigate('Help')}>Help</a>
                        </li>
                        <li className="current">
                            <a href="#download" role="button"
                               onClick={() => _this.props.navigate('Download')}>Download</a>
                        </li>
                        <li>
                            <a href="#policy" role="button" onClick={() => _this.props.navigate('Policy')}>Privacy
                                Policy</a>
                        </li>
                        <li>
                            <a href="mailto:feedback@answersolutions.net?subject=StudyCards">Contact us</a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
        </div>
    }
}

export default Download;