import React, {Component} from 'react';
import './Policy.css';
import './TopMenu.css';
import './BotomMenu.css';
import firebaseApp from './firebase'
import {getAnalytics, logEvent, setCurrentScreen} from "firebase/analytics";

const analytics = getAnalytics(firebaseApp);

let _this;

class Privacy extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;
    }

    componentDidMount() {
        setCurrentScreen(analytics, window.location.pathname);
        logEvent(analytics, 'screen_view')
    }

    render() {
        return <div>
            <div className="container">
                <br/>
                <div className="card" id="shellPrimaryNoPadding">
                    <div className="top_menu containerNoMargin">
                        <ul>
                            <li>
                                <a href="#home" role="button" onClick={() => _this.props.navigate('Home')}>Home</a>
                            </li>
                            <li>
                                <a href="#help" role="button" onClick={() => _this.props.navigate('Help')}>Help</a>
                            </li>
                            <li>
                                <a href="#download" role="button"
                                   onClick={() => _this.props.navigate('Download')}>Download</a>
                            </li>
                            <li className="current">
                                <a href="#policy" role="button" onClick={() => _this.props.navigate('Policy')}>Privacy
                                    Policy</a>
                            </li>
                            <li>
                                <a href="mailto:feedback@answersolutions.net?subject=StudyCards">Contact us</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <br/>
                <p>
                    <h2 className="policy">Privacy Policy and Terms of Use</h2>
                </p>
                <p>
                    <b>StudyCards app</b> ("us", "we", or "our") operates https://studycardsapp.com (the "Service").
                    This "Service" does not collect any Personal Information. This "Service" does not store your data on
                    the cloud.
                    Therefore StudyCards app does not take responsibility and has no control over the performance and
                    speed of the app. By using this "Service" you acknowledge that we will not be liable for any
                    damages, claims or other
                    liability arising from and / or related use of Web sites owned by third parties.
                    These terms of Service ("Terms") and the StudyCards app privacy policy (the "privacy policy") are
                    the accesses and terms of your use in the website and the mobile apps. Using this site and the
                    mobile
                    apps is an adult or a parent or and / or guardian of any minor who approved the use of the Service
                    and all those who take responsibility for a minor for using this site.
                </p>
                <p>
                    <h3 className="policy">Disclaimer on the ChatGPT Wizard Feature in StudyCards App</h3>
                </p>
                <p>
                    With the introduction of the ChatGPT Wizard feature in StudyCards App, the process of creating
                    customized decks has been greatly simplified. However, it is important to note that the utilization
                    of Artificial Intelligence (AI) in generating content may result in occasional inaccuracies, harmful
                    instructions, or biased material. Additionally, the AI's knowledge base is limited to events and
                    information available up to 2021, and it may not be able to provide up-to-date or comprehensive
                    information on current events or evolving topics. Therefore, it is the responsibility of the user to
                    exercise caution and diligence in reviewing and verifying the accuracy and reliability of the
                    content generated by the ChatGPT Wizard feature before utilizing it for academic or educational
                    purposes. By using this feature, users agree to hold StudyCards App harmless from any and all
                    liability arising from the use of such content.
                </p>
                <p>
                    <h3 className="policy">Provision</h3>
                </p>
                <p>
                    The Service has different expenses for maintaining the infrastructure. So Service reserve the rights
                    to display advertisements and promotions.
                    The manner, mode and extent of advertising by StudyCards app on the Services are subject to change
                    without specific notice to you.
                    In case these ads or/and another sources of revenue will not cover the expenses - we are reserve the
                    rights to stop the Service.
                    You acknowledge and agree that StudyCards app may stop (permanently or temporarily) providing the
                    Services (or any features within the Services), without prior notice to you.
                </p>
                <p>
                    <h3 className="policy">Cookie and similar technologies</h3>
                </p>
                <p>
                    Cookies are files with small amount of data, which may include an anonymous unique identifier.
                    Cookies are sent to your browser from a website and stored on your computer's hard drive. Like many
                    sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies
                    or to indicate when a cookie is being sent. However, if you do not accept cookies, This "Service"
                    may not work properly. We may ask advertisers or other partners to serve ads or services to your
                    devices, which may use cookies or similar technologies placed by us or the third party.
                </p>
                <p>
                    <h3 className="policy">Analytics information</h3>
                </p>
                <p>
                    We use third-party analytics tools to help us measure traffic and usage trends for the Service.
                    These tools collect
                    information sent by your device or our Service, including the web pages you visit, add-ons, and
                    other information
                    that assists us in improving the Service. We collect and use this analytics information with
                    analytics information
                    from other Users so that it cannot reasonably be used to identify any particular individual User.
                </p>
                <p>
                    <h3 className="policy">Security</h3>
                </p>
                <p>
                    Remember that no method of transmission over the internet, or method of electronic storage is 100%
                    secure and
                    reliable, and I cannot guarantee its absolute security.
                </p>
                <p>
                    <h3 className="policy">Links To Other Sites</h3>
                </p>
                <p>
                    Our Service may contain links to other sites that are not operated by us. If you click on a third
                    party link, you
                    will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of
                    every site you
                    visit.
                    We have no control over, and assume no responsibility for the content, privacy policies or practices
                    of any third
                    party sites or services.
                </p>
                <p>
                    <h3 className="policy">Log file information</h3>
                </p>
                <p>
                    Log file information is automatically reported by your browser each time you make a request to
                    access (i.e., visit)
                    a web page or app. It can also be provided when the content of the web page or app is downloaded to
                    your browser or
                    device. When you use our Service, our servers automatically record certain log file information,
                    including your web
                    request, Internet Protocol ("IP") address, browser type.
                </p>
                <p>
                    <h3 className="policy">Children’s Privacy</h3>
                </p>
                <p>
                    These Services do not address anyone under the age of 13.
                </p>
                <p><h3 className="policy">Changes To This Privacy Policy</h3></p>
                <p>
                    This Privacy Policy is effective as of January 1, 2021 and will remain in effect except with respect
                    to any changes
                    in its provisions in the future, which will be in effect immediately after being posted on this
                    page.
                </p>
                <p>
                    We reserve the right to update or change our Privacy Policy at any time and you should check this
                    Privacy Policy periodically. Your continued use of the Service after we post any modifications to
                    the Privacy
                    Policy on this page will constitute your acknowledgment of the modifications and your consent to
                    abide and be bound
                    by the modified Privacy Policy.
                    If we make any material changes to this Privacy Policy, we will notify you either through the email
                    address you have provided us, or by placing a prominent notice on our website.
                    Make sure you read these Terms and the Privacy Policy carefully.

                </p>
                <p><h3 className="policy">Accepting the Terms</h3></p>
                <p>
                    In order to use the Services, you must first agree to the Terms.
                    You may not use the Services if you do not accept the Terms.
                    You can accept the Terms by actually using the Services/App. In this case,
                    you understand and agree that StudyCards app will treat your use of the Services as acceptance of
                    the Terms from that point onwards.
                    You may stop using the Services at any time. You do not need to specifically inform StudyCards app
                    when you stop using the Services.
                </p>
                <br/>
                <div className="card" id="shellPrimaryNoPadding">
                    <div className="fast_nav containerNoMargin">
                        <ul>
                            <li className="first">
                                <a href="https://answersolutions.net" target="_blank" rel="noopener noreferrer">2022 ©
                                    AnswerSolutions LLC</a>
                            </li>
                            <li>
                                <a href="#home" role="button" onClick={() => _this.props.navigate('Home')}>Home</a>
                            </li>
                            <li>
                                <a href="#help" role="button" onClick={() => _this.props.navigate('Help')}>Help</a>
                            </li>
                            <li>
                                <a href="#download" role="button"
                                   onClick={() => _this.props.navigate('Download')}>Download</a>
                            </li>
                            <li className="current">
                                <a href="#policy" role="button" onClick={() => _this.props.navigate('Policy')}>Privacy
                                    Policy</a>
                            </li>
                            <li>
                                <a href="mailto:feedback@answersolutions.net?subject=StudyCards">Contact us</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <br/>

            </div>
        </div>
    }
}

export default Privacy;