import React, {Component} from 'react';
import ReactPlayer from "react-player"
import './Home.css';
import './TopMenu.css';
import './BotomMenu.css';
import firebaseApp from './firebase'
import {getAnalytics, logEvent, setCurrentScreen} from "firebase/analytics";

const analytics = getAnalytics(firebaseApp);

let _this;

class Home extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;

    }

    componentDidMount() {
        setCurrentScreen(analytics, window.location.pathname);
        logEvent(analytics, 'screen_view')
    }


    render() {
        return <div className="container">
            <br/>
            <div className="card" id="shellPrimaryNoPadding">
                <div className="top_menu containerNoMargin">
                    <ul>
                        <li className="current">
                            <a href="#home" role="button" onClick={() => _this.props.navigate('Home')}>Home</a>
                        </li>
                        <li>
                            <a href="#help" role="button" onClick={() => _this.props.navigate('Help')}>Help</a>
                        </li>
                        <li>
                            <a href="#download" role="button"
                               onClick={() => _this.props.navigate('Download')}>Download</a>
                        </li>
                        <li>
                            <a href="#policy" role="button" onClick={() => _this.props.navigate('Policy')}>Privacy
                                Policy</a>
                        </li>
                        <li>
                            <a href="mailto:feedback@answersolutions.net?subject=StudyCards">Contact us</a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
            <div className="card" id="shell4">
                <div id="content">
                    <p>
                        <div>
                            <div id="app_icon">
                                <img style={{borderRadius: '7%'}} src="/favicon.png" align="left" hspace="16"
                                     vspace="16" alt="app icon"/>
                            </div>

                            <div>
                                <br/>
                                <p><h2>StudyCards App: Talking Flashcards</h2></p>
                                <p><h4>AI Powered Flashcards Maker, You can memorize things and study by swiping the
                                    cards one by one. Or, you can LISTEN on repeat!</h4></p>
                            </div>
                        </div>
                    </p>
                    <p>
                        StudyCards App powered by AI is a modern flashcard app with a paper-and-sticky-note interface.
                        It also features embedded iOS/Android text-to-speech engine, which can read the text from the
                        sides of your cards aloud in a natural-sounding voice. Create custom decks with the assistance
                        from Artificial Intelligence, organize your study materials, and track your progress, all in one
                        convenient app.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shell1">
                <div id="content">
                    <p>
                        With StudyCards, you can easily create your own flashcard deck with a question on one side and
                        the answer on the other. The app also allows you to choose a language for each side, and will
                        pronounce both the questions and answers for you, even in eyes-free mode.

                        But that's not all - StudyCards is the only app that lets you upload your flashcards to your
                        watch, so you can listen and memorize information even when you're running, biking, or hiking
                        without your phone. With StudyCards, learning is more convenient and accessible than ever
                        before.
                    </p>
                    <br/>
                    <p>
                        The App is also designed to help people with low vision, ADHD, dyslexia, and other reading
                        disorders.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shell2">
                <div id="content">
                    <p>
                        With the help of 'ChatGPT Wizard', you can generate custom flashcards tailored to whatever
                        you're trying to learn.
                        <ul>
                            <li> Enhances your vocabulary for books or movies;</li>
                            <li> Helps you with school curriculum</li>
                            <li> Lets you learn general facts on any topic</li>
                            <li> Prepares you for any job interview with questions and answers</li>
                        </ul>
                        We're constantly working to improve StudyCards App, and we have even more exciting updates
                        planned for the ChatGPT Wizard in future releases. Stay tuned for even more options and features
                        to enhance your flashcard creation experience!
                    </p>
                    <h4>For More Information about ChatGPT Wizard check out this tutorial</h4>
                    <a href="https://medium.com/geekculture/create-custom-flashcards-for-studycards-app-with-the-new-chatgpt-wizard-a-step-by-step-guide-e2326279427c" target="_blank" rel="noopener noreferrer">ChatGPT Wizard Tutorial</a>
                    <br/>
                </div>
            </div>
            <br/>
            <div className="card" id="shell6">
                <div id="content">
                    <p>
                        Very simple:
                        <ul>
                            <li> No Account or registration needed</li>
                            <li> Works on smart watches</li>
                            <li> Talking cards can speak any language supported by your phone and watch</li>
                            <li> Easy to make your own custom deck of cards right on the phone</li>
                            <li> Easy to share/export/import the decks</li>
                            <li> Learn words and phrases from the new language</li>
                            <li> Remember math definitions and formulas</li>
                            <li> Memorize songs and poems</li>
                            <li> Create a deck of cards with riddles or jokes and share them with your friends</li>
                            <li><span className="crimson_text">It’s never been easier to memorize - enjoy!</span>
                            </li>
                        </ul>
                    </p>
                    <br/>
                    <p>
                        Mobile app is available for modern Apple iOS/WatchOS and for Android/WearOS devices.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shell5">
                <div id="content">
                    <p>
                        You can also create a deck of flashcards using any text editor or spreadsheet program, such as
                        Excel. Save the file as a CSV with the vertical bar character (|) as the delimiter. Once you've
                        saved the file, you can easily transfer it to your phone by sending it as an email or message
                        attachment. Open the deck by clicking on attachment or importing it from your phone's disk.
                        <p>
                            <h3>CSV File example(two cards):</h3>
                            <div className="comment_text" id="comment_text" contentEditable="true">
                                side1|side2|English|English|Simple Math<br/>
                                Two plus two|Equal four<br/>
                                Three plus five|Equal eight<br/>
                            </div>
                        </p>
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shell3">
                <div className="qr_container">
                    <ReactPlayer
                        url="https://youtu.be/sPclf0j3xvg"
                    />
                </div>
            </div>
            <br/>
            <h2>Download Decks to your Phone</h2>
            <br/>
            <div>
                <a href="/decs/USAPresidents.studyCards" download style={{textDecoration: 'none', color: '#040404'}}>
                    <div className="downloadTableTop" id="downloadTableTop">
                        <div id="content">
                            <h2>USA Presidents (46 cards)</h2>
                            <p>English to English</p>
                        </div>
                    </div>
                </a>
                <a href="/decs/Roblox_Terms.studyCards" download style={{textDecoration: 'none', color: '#040404'}}>
                    <div className="downloadTableMiddle" id="downloadTableMiddle">
                        <div id="content">
                            <h2>Roblox Terms and Slang for Parents (20 cards)</h2>
                            <p>English to English</p>
                        </div>
                    </div>
                </a>
                <a href="/decs/Minecraft_Terms.studyCards" download style={{textDecoration: 'none', color: '#040404'}}>
                    <div className="downloadTableMiddle" id="downloadTableMiddle">
                        <div id="content">
                            <h2>Minecraft Terms and Slang for Parents (15 cards)</h2>
                            <p>English to English</p>
                        </div>
                    </div>
                </a>

                <a href="/decs/pi_facts.studyCards" download style={{textDecoration: 'none', color: '#040404'}}>
                    <div className="downloadTableMiddle" id="downloadTableMiddle">
                        <div id="content">
                            <h2>50 facts about the Pi number (51 flashcards)</h2>
                            <p>English to English</p>
                        </div>
                    </div>
                </a>
                <a href="/decs/st_patric_facts.studyCards" download style={{textDecoration: 'none', color: '#040404'}}>
                    <div className="downloadTableMiddle" id="downloadTableMiddle">
                        <div id="content">
                            <h2>50 facts about the St. Patric's Day (50 flashcards)</h2>
                            <p>English to English</p>
                        </div>
                    </div>
                </a>
                <a href="/decs/140_English.studyCards" download style={{textDecoration: 'none', color: '#040404'}}>
                    <div className="downloadTableMiddle" id="downloadTableMiddle">
                        <div id="content">
                            <h2>140 English (140 cards)</h2>
                            <p>English to Russian</p>
                        </div>
                    </div>
                </a>
                <a href="/decs/6_Haikus.studyCards" download style={{textDecoration: 'none', color: '#040404'}}>
                    <div className="downloadTableMiddle" id="downloadTableMiddle">
                        <div id="content">
                            <h2>6 Haiku (6 cards)</h2>
                            <p>English to English</p>
                        </div>
                    </div>
                </a>
                <a href="/decs/Geometry.studyCards" download style={{textDecoration: 'none', color: '#040404'}}>
                    <div className="downloadTableMiddle" id="downloadTableMiddle">
                        <div id="content">
                            <h2>Geometry (14 cards)</h2>
                            <p>English to English</p>
                        </div>
                    </div>
                </a>
                <a href="/decs/50_Android_InterviewQ&A.studyCards" download
                   style={{textDecoration: 'none', color: '#040404'}}>
                    <div className="downloadTableBottom" id="downloadTableBottom">
                        <div id="content">
                            <h2>50 Android Interview Questions</h2>
                            <p>English to English</p>
                        </div>
                    </div>
                </a>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
            <div>
                <img width="100%" src="/qr_study_cards.png" alt="Apple and Google Store"/>
            </div>
            <div className="fast_nav container">
                <h2>Download Mobile app</h2>
                <ul>
                    <li className="first">
                        <a href="https://apps.apple.com/us/app/study-cards-help-to-memorize/id1436913731"
                           target="_blank"
                           rel="noopener">
                            <img src="/app_store.png" scale="0" alt="itunes"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://play.google.com/store/apps/details?id=com.nes.studycards"
                           target="_blank"
                           rel="noopener">
                            <img src="/google_play.png" scale="0" alt="google play"/>
                        </a>
                    </li>
                </ul>
            </div>
            <br/>
            <div className="card" id="shellPrimaryNoPadding">
                <div className="fast_nav containerNoMargin">
                    <ul>
                        <li className="first">
                            <a href="https://answersolutions.net" target="_blank" rel="noopener noreferrer">2022 ©
                                AnswerSolutions LLC</a>
                        </li>
                        <li className="current">
                            <a href="#home" role="button" onClick={() => _this.props.navigate('Home')}>Home</a>
                        </li>
                        <li>
                            <a href="#help" role="button" onClick={() => _this.props.navigate('Help')}>Help</a>
                        </li>
                        <li>
                            <a href="#download" role="button"
                               onClick={() => _this.props.navigate('Download')}>Download</a>
                        </li>
                        <li>
                            <a href="#policy" role="button" onClick={() => _this.props.navigate('Policy')}>Privacy
                                Policy</a>
                        </li>
                        <li>
                            <a href="mailto:feedback@answersolutions.net?subject=StudyCards">Contact us</a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
        </div>
    }
}

export default Home;