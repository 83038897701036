import React, {Component} from 'react';
import './AdIos.css';
import firebaseApp from './firebase'
import {getAnalytics, logEvent, setCurrentScreen} from "firebase/analytics";

const analytics = getAnalytics(firebaseApp);

let _this;

class AdAndroid extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;

    }

    componentDidMount() {
        setCurrentScreen(analytics, window.location.pathname);
        logEvent(analytics, 'screen_view')
    }


    render() {
        return <div className="container">
            <br/>
            <a href="https://play.google.com/store/apps/details?id=com.nes.studycards"
               target="_blank" download style={{textDecoration: 'none', color: '#040404'}}>
                <div className="adTableTop" id="adTableTop">
                    <div id="content">
                        <p>
                            <img src="/ads/studycards.png" align="left" hspace="5" vspace="5" alt="app icon"
                                 width="54px"/>
                            <p>
                                <b>StudyCards app helps memorize things!</b>
                                <br/>Listen to the Talking Flashcards
                            </p>
                        </p>
                        <p>
                            <b>StudyCards app</b> is a Talking Flashcards. Its user interface
                            mimics the paper sticky notes and cards.
                            The app also can convert text to a natural sounding voice using Apple's embedded
                            text-to-speech
                            engine.
                        </p>
                    </div>
                </div>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.umnes.stickies2go"
               target="_blank" download style={{textDecoration: 'none', color: '#040404'}}>
                <div className="adTableMiddle" id="adTableMiddle">
                    <div id="content">
                        <p>
                            <img src="/ads/airstickies.png" align="left" hspace="5" vspace="5" alt="app icon"
                                 width="54px"/>
                            <p>
                                <b>AirSync your Stickies</b>
                                <br/>Get the stickies from your Mac
                            </p>
                        </p>
                        <p>
                            <b>AirSync</b> – effortless synchronization for Apple Stickies with your Mac.
                            AirSync synchronizes the regular MacOS's Stickies with iPhone.
                            It's as simple as typing magic number on your Mac.
                        </p>
                    </div>
                </div>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.nes.pdj.photodj"
               target="_blank" download style={{textDecoration: 'none', color: '#040404'}}>
                <div className="adTableBottom" id="adTableBottom">
                    <div id="content">
                        <p>
                            <img src="/ads/photodj.png" align="left" hspace="5" vspace="5" alt="app icon"
                                 width="54px"/>
                            <p>
                                <b>PhotoDj - Your Photos on TV</b>
                                <br/>Display photos on BIGER Screen
                            </p>
                        </p>
                        <p>
                            <b>PhotoDj app</b> - show photos to your friends, family, or TEAM on a BIG Screen of a SmartTV, computer,
                            Tablet, etc.
                            It does not leave any footage on the presentation device or server.

                            With the PhotoDj app, you can zoom in and display the photos or GIF images from your phone's
                            gallery, on Smart TV, PC, Mac, Tablet, another phone, etc. Without any registration.
                        </p>
                    </div>
                </div>
            </a>
            <br/>
        </div>
    }
}

export default AdAndroid;